/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import styled from '@emotion/styled';
import { FaWhatsapp } from 'react-icons/fa';
import FBCustomerChat from './src/components/FBCustomerChat';
import './src/html.css';

const SocialLinksWAFB = styled.div`
  .facebook {
    .fb_customer_chat_bounce_in_v2 {
      bottom: 7rem !important;
      right: 0.25rem !important;
    }
    .fb_dialog {
      bottom: 4.5rem !important;
      right: 0.75rem !important;
      transform: scale(0.8888);
    }
  }
  .whatsapp {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    border-radius: 100%;
    padding: 0.5rem;
    background-color: #76ca6e;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.3);
    z-index: 100;
    a {
      display: flex;
      text-decoration: none;
      color: white;
      font-size: 2rem;
    }
  }
`;

export const wrapPageElement = ({ element }) => {
  /* const FB_PAGE_ID = '113285790412431';
  const WHATSAPP_NUMBER = '593998362175'; */
  return (
    <>
      {element}
      {/* <SocialLinksWAFB>
        <div className="facebook">
          <FBCustomerChat pageId={FB_PAGE_ID} />
        </div>
        <div className="whatsapp">
          <a href={`https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER}`} target="_blank" rel="noreferrer noopener">
            <FaWhatsapp id="whatsapp-icon" />
          </a>
        </div>
      </SocialLinksWAFB> */}
    </>
  );
};
